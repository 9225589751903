(function ($) {
  Drupal.behaviors.gnavLinksV1 = {
    attached: false,
    attach: function (context) {
      var self = this;
      var $elementsToWrap = $('.js-gnav-links-column', context);
      var $newParent = $('<div class="gnav-links__parent"></div>');

      if (self.attached) {
        return;
      }
      self.attached = true;
      $elementsToWrap.wrapAll($newParent);
    }
  };
})(jQuery);
